@charset "UTF-8";

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  background: '#FFFFFF';
  font-family: 'DM Sans', system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  margin: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}

h6,
h5,
h4,
h3,
h2,
h1 {
  margin: 0;
}

p {
  margin: 0;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  outline: 0;
  padding: 0;
}

input:focus-visible {
  outline: 0;
}

[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}
